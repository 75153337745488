import { styled } from 'Theme/stitches.config';
import MobileQuickSearch from 'DesignComponents/Organisms/QuickSearch/MobileQuickSearch/MobileQuickSearch';
import { useCallback, useReducer } from 'react';
import menuReducer from '../Reducers/menuReducer';
import loadable from '@loadable/component';
import { EventDispatcher, ON_MOBILE_QUICKSEARCH_TOGGLE } from 'Shared/Common/EventDispatcher';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { Cart, Close, Menu, Search, User } from 'DesignSystem/Icons';
import InteractableContentLink, { TargetTypes } from 'Shared/Components/Links/InteractableContentLink';
import { useHeaderData } from '../Header';
import { focusStateRoundedStyle, focusStateStyle } from 'Shared/Common/focusState';
import { mapModalView } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import { mapModalFooterView } from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import { ModalFooterLayoutOption } from 'Enums/ModalFooterLayout.enum';
import MiniCartContent from 'Commerce/Cart/MiniCart/MiniCartContent';
import MiniCartModalFooter from 'Commerce/Cart/MiniCart/MiniCartModalFooter';
import { GetCart } from 'Commerce/Cart/Cart';
import ItemsNotifier from 'DesignComponents/Atoms/ItemsNotifier/ItemsNotifier';
import TopBanner from 'DesignComponents/Organisms/TopBanner/TopBanner';
import GenericModal from 'DesignComponents/Organisms/Modal/GenericModal/GenericModal';
import SiteLogo from 'DesignSystem/Icons/Icons/SiteLogo';
import { useAppSettings, useTranslations } from '../../../../context/init-data.context';

const NavigationMenu = loadable(
  () => import('DesignComponents/Organisms/Menu/MobileMenu/Menu')
);

function MobileHeader() {
  const {
    navigationLinks,
    topLinks,
    uniqueSellingPoints,
    myPagesLink,
    siteLogotype,
  } = useHeaderData();
  const {
    commonLabels,
    cartLabels: { cartHeader },
  } = useTranslations();
  const { languageRoute } = useAppSettings();
  const { cart } = GetCart(languageRoute);

  const [{ menuIsOpen, mountMenu }, menuDispatch] = useReducer(menuReducer, {
    menuIsOpen: false,
    mountMenu: true,
    menuId: null,
  });

  const { modalState, toggleModal } = useUiState();

  const openMiniCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleModal(
      !modalState.display,
      'MiniCart',
      cartHeader,
      {
        component: mapModalView<typeof MiniCartContent>({
          type: 'MiniCart',
          props: {},
        }),
      },
      mapModalFooterView<typeof MiniCartModalFooter>({
        type: 'MiniCartModalFooter',
        props: {
          displayModal: !modalState.display,
          modalFooterContent: {
            footerLayout: ModalFooterLayoutOption.STACKED,
          },
        },
      }),
      e.currentTarget as HTMLElement
    );
  };

  const onMenuClose = useCallback(() => {
    menuDispatch({ type: 'closeMenu' });
  }, [menuDispatch]);

  const onMenuCloseWithSearchToggle = useCallback(() => {
    menuDispatch({ type: 'closeMenu' });
    EventDispatcher.dispatch(ON_MOBILE_QUICKSEARCH_TOGGLE, null);
  }, [menuDispatch]);

  return (
    <>
      {uniqueSellingPoints.length > 0 && (
        <TopBanner uspItems={uniqueSellingPoints} />
      )}
      <HeaderWrapper>
        <HeaderLeftWrapper>
          <MobileMenuButton
            onClick={() => {
              menuDispatch(
                menuIsOpen ? { type: 'closeMenu' } : { type: 'mountAndOpen' }
              );
            }}
          >
            {!menuIsOpen ? (
              <Menu size="m" color="primary" />
            ) : (
              <Close size="m" color="primary" />
            )}
          </MobileMenuButton>

          <ButtonWrapper
            aria-label={commonLabels && commonLabels.search}
            onClick={onMenuCloseWithSearchToggle}
          >
            <Search size="m" color="primary" />
          </ButtonWrapper>
        </HeaderLeftWrapper>
        <HeaderCenterWrapper>
          {siteLogotype?.src && (
            <InteractableContentLink href={`/`}>
              <SiteLogo src={siteLogotype.src} width={100} />
            </InteractableContentLink>
          )}
        </HeaderCenterWrapper>
        <HeaderRightWrapper>
          {myPagesLink && (
            <InteractableContentLink
              href={myPagesLink?.href ?? ''}
              target={(myPagesLink?.target as TargetTypes) ?? ''}
              title={myPagesLink?.text ?? ''}
            >
              <User color="primary" size="m" />
            </InteractableContentLink>
          )}
          <ButtonWrapper onClick={openMiniCart} aria-label={'cart button'}>
            <Cart color="primary" size="m" />
            {cart && <ItemsNotifier items={cart?.numberOfItems} />}
          </ButtonWrapper>
        </HeaderRightWrapper>
        <GenericModal />
        <MobileQuickSearch onMenuClose={onMenuClose} />
      </HeaderWrapper>
      {mountMenu && (
        <NavigationMenu
          isOpen={menuIsOpen}
          menuLinks={navigationLinks.links}
          staticLinks={topLinks}
        />
      )}
    </>
  );
}

export default MobileHeader;

const HeaderWrapper = styled('div', {
  position: 'relative',
  borderBottom: '1px solid $surfaceVariant',
  backgroundColor: '$surface',
  t: 0,
  px: '$s150',
  w: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '$mobileHeaderUpperHeight',
  zIndex: '$zIndices$Header',
});

const MobileMenuButton = styled('button', {
  ...focusStateStyle,
});

const HeaderLeftWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flex: '1',
  marginRight: 'auto',
  gap: '$s150',
});

const HeaderCenterWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flex: '1',
  justifyContent: 'center',
});

const HeaderRightWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$s150',
  flex: '1',
  justifyContent: 'flex-end',
});

const ButtonWrapper = styled('button', {
  position: 'relative',
  display: 'flex',
  ...focusStateRoundedStyle,
});
